#app-container{
  overflow: hidden;
}
.custom-no-border {
  padding: 0;
  margin: 0;
}

.fab {
  margin: 0px;
  top: auto;
  right: 20px;
  bottom: 20px;
  left: auto;
  position: fixed;
  z-index: 9999999;
}
.fab-snack {
  margin: 0px;
  top: auto;
  right: 20px;
  bottom: 50px;
  left: auto;
  position: fixed;
  z-index: 9999999;
}
.fabscreen {
  margin: 0px;
  top: auto;
  right: 30px;
  bottom: 90px;
  left: auto;
  position: fixed;
}

.fab-layout-expand {
  margin: 0px;
  top: auto;
  right: 71px;
  bottom: 86px;
  left: auto;
  position: fixed;
  background: white;
}
@keyframes fab-layout-objective-move {
  from {
    right: 10px;
  }
  to {
    right: 85px;
  }
}
.fab-add {
  margin: 0px;

  right: 20px;
  bottom: 24px;

  position: fixed;
  background: transparent;

  z-index: 99;
}

.fab-add-start-text {
  margin: 0px;
  top: auto;
  bottom: 40px;
  animation: LeftAndRight 1s linear infinite;
  font-size: 24px;
  right: 100px;
  opacity: 1;
  z-index: 9999999;
  display: block;
  height: auto;

  position: fixed;
  background: transparent;
  z-index: 97;
  /*padding-left: 16px;
  padding-right: 8px;*/
}
@keyframes LeftAndRight {
  0%,
  100% {
    right: 110px;
  }
  50% {
    right: 90px;
  }
}
.fab-add-text {
  margin: 0px;
  top: auto;
  right: 0px;
  bottom: 34px;
  left: auto;

  position: fixed;
  background: transparent;
  z-index: 97;
  /*padding-left: 16px;
  padding-right: 8px;*/
  vertical-align: middle;
  letter-spacing: 0px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;

  height: 0;
  width: 0;
  border-radius: 2px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  color: #ececec;
  display: inline-block;
  line-height: 1em;
  padding: 10px;
  margin-right: 25px;
  text-overflow: ellipsis;
  /*  vertoca;-align: middle;*/
  background: rgba(0, 0, 0, 0.8);
}
.fab-mobile-hover {
  position: fixed;
  z-index: 9999999;
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.07);
}
.fab-add-text-transition {
  transition: display 0ms right 900ms opacity 0.2ms ease-in-out;
  right: 80px;
  opacity: 1;
  z-index: 9999999;
  display: block;
  height: auto;
  width: auto;
}

.fab-layout-extra-button {
  margin: 0px;
  top: auto;
  right: 23px;
  bottom: 24px;
  left: auto;
  position: fixed;
  background: transparent;
  transition: bottom 200ms;
  opacity: 0;
  z-index: 9999999;
  width: 0px;
}
.fab-layout-note-transition {
  transition: width 0ms bottom 200ms opacity 0.2ms ease-in-out;
  bottom: 92px;
  opacity: 1;
  width: auto;
}
.fab-layout-photo-transition {
  transition: width 0ms bottom 200ms opacity 0.2ms ease-in-out;
  bottom: 142px;
  opacity: 1;
  width: auto;
}

.no-hover :hover {
  background-color: transparent !important;
}

.on-hover-underline :hover {
  text-decoration: underline !important;
  font-weight: bold;
}

.full-screen-custom {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 999999;
}

.tag-bar .nav {
  // General
  a {
    display: block;
    position: relative;
    text-decoration: none;
    &:hover {
      cursor: pointer;
    }
  }
  li {
    position: relative;
    .badge {
      position: absolute;
      padding: 3px 6px;
    }
  }
  .nav-header {
    margin: 15px 15px 5px;
    font-size: $font-size-sm;
  }
  .nav-divider + .nav-header {
    margin-top: 5px;
  }

  // first level li
  > li {
    > a {
      line-height: 24px;
    }
  }
  .nav-icon {
    display: inline-block;

    &.material-icons {
      width: 24px;
      height: 24px; // with font-size 20px, material-icons will be at least 25px high
      font-size: 18px;
      line-height: 24px;
      text-align: center;

      &.nav-dot {
        font-size: 16px;
      }
    }
  }

  // sub ul
  ul {
    list-style: none;
    padding: 0;

    li {
      > a {
        padding: 10px 18px !important; // Align with 1st level: 18px = 16px (1st level) + 2px ()

        .material-icons {
          width: 20px;
          height: 20px; // with font-size 20px, material-icons will be at least 25px high
          font-size: 16px;
          line-height: 1;
          text-align: center;
          vertical-align: middle;
          margin-bottom: -2px;
        }
      }
    }
  }

  // sub2 ul
  ul ul {
    li {
      > a {
        padding: 10px 15px 10px 56px !important; // With icon, padding-left: 18(a padding-left)+20(icon width)+18(nav-icon margin-right)
      }
    }
  }

  // sub3 ul
  ul ul ul {
    li {
      > a {
        padding-left: 74px !important;
      }
    }
  }
}
$tagbar_text_color: red;
// Multi-level nav color
// Default dark background style
// use with directive "accordionNav", which add .open class on li
.tag-bar {
  /*background-color: $sidebar_bg_color;*/

  .nav {
    // General
    color: $tagbar_text_color !important;
    a {
      color: $tagbar_text_color !important;
    }
    .nav-header {
      color: $text-muted;
    }
    .nav-divider {
      background-color: rgba(0, 0, 0, 0.15);
    }
    li {
      > a {
        &:hover,
        &:focus {
          background-color: $nav_link_hover_bg !important;
          color: $nav_link_hover_color !important;
        }
      }
      &.active {
        > a,
        > a:hover,
        > a:focus {
          background-color: $nav_link_active_bg !important;
          color: $nav_link_active_color !important;
        }
      }
      &.open {
        > a,
        > a:hover,
        > a:focus {
          background-color: $nav_link_open_bg !important;
          color: $nav_link_active_color !important;
        }
        > .icon-has-ul {
        }
      }
    }

    // sub ul
    ul {
      background-color: $nav_sub_bg;
      li {
        &.active,
        &.open {
          > a,
          > a:hover,
          > a:focus {
            background-color: $nav_sub2_bg;
          }
        }
      }
    }

    // >= sub2 ul
    ul ul {
      background-color: $nav_sub2_bg;
      > li {
        &.active,
        &.open {
          > a,
          > a:hover,
          > a:focus {
            background-color: $nav_sub3_bg;
            color: $nav_sub_link_active_color;
          }
        }
      }
    }

    ul ul ul {
      background-color: $nav_sub3_bg;
    }
  }
}
.ql-loading-none {
}
.ql-processing {
  font-family: FontAwesome;
  font-size: 0.8em;

  content: "\f021";
  color: gray;
  cursor: pointer;
  pointer-events: all;
  animation: fa-spin 2s infinite linear;
  display: inline-block;
  white-space: nowrap;
}

.phone-toolbar {
  /*position: absolute;*/
  width: 100%;
  height: 79px;
  bottom: 0px;
}
.notebuttons {
  position: absolute;
  top: 0px;
  right: 5px;
  z-index: 9;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.quill-mobile-header {
  position: fixed;
  display: block;
  z-index: 1000;
  height: 64px;
  width: 100%;
  transition: left 0.4s ease-in-out;
  left: 100%;
}
.quill-mobile-window {
  height: 100%;
  background: white;
  position: absolute;

  top: 0;
  width: 100%;
  left: 100%;
  transition: left 0.4s ease-in-out;
  z-index: 1000;
}
.quill-mobile-window-active {
  left: 0%;
  .quill-mobile-header {
    left: 0%;
  }
}

.bundle-number {
  background-color: lightgrey;
  font-size: 12px;
  font-weight: bolder;
  padding: 3px 6px 3px 6px;
  margin-right: 5px;
  white-space: nowrap;
}
.mobile-scroll {
  overflow: scroll;

  -webkit-overflow-scrolling: "touch";
}
.simple-search-input{
  width:100%;
  border:none;

font-size: 16px !important;

line-height: 25px !important;
  
}
.simple-search-input:focus {
  outline: none;
}
.login-area {
  padding-top: 100px;
  @media (max-width: map-get($grid-breakpoints, md)) {
    padding-top: 60px;
  }
}

.mobile-form-with-header {
  overflow: scroll;

  -webkit-overflow-scrolling: "touch";
}

.todo-preview {
  height: 200px;
  margin-top: -200px;
  position: relative;
  width: 100%;
  overflow: hidden;
  z-index: 999;
  cursor: pointer;
  background: linear-gradient(rgba(245, 245, 245, 0), rgba(245, 245, 245, 1));
  &:hover {
    transition: background-color 0.8s linear;

    background: linear-gradient(
      rgba(245, 245, 245, 0),
      rgba(245, 245, 245, 0.75)
    );
  }
}

//PH
.color-left-nav {
  @media (max-width: map-get($grid-breakpoints, sm)) {
    background-color: rgb(250, 250, 250);
    border-right-width: 1px;
    border-right-style: solid;
    border-right-color: rgb(250, 250, 250);
  }
}

.todo-selected {
}

.todo-focused {
  border-left: #311b92;
  border-left-width: 4px;
  border-left-style: solid;
}

.hotkey-button {
  padding: 4px;
  background: darkgrey;
  margin: 4px;
  color:white;
}
.hotkey-row-pad {
  padding: 5px;
}

.todo-listitem {
  width: 100%;
  min-height: 50px;
  font-size: 14px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 16px;
  padding-bottom: 16px;
  cursor: pointer;
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */

  /* Rules below not implemented in browsers yet */
  -o-user-select: none;
  user-select: none;
}
.todo-subtext {
  color: #757575;
  font-size: 14px;
  line-height: 16px;
  white-space: nowrap;

}
.todo-subtext-with-wrap {
  color: #757575;
  font-size: 14px;
  line-height: 16px;
}
.todo-subtext-action {
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}
.todo-no-padding {
  padding-left: 0px;
  padding-right: 0px;
}
.todo-no-gutters {
  padding-left: 5px;
}
.center-block {
  display: table;
  margin: auto;
}

// In case you're dealing with a inline element apply this to the parent
.text-center {
  text-align: center;
}
.todo-listitem-bottom-text {
  font-size: 14px;
  line-height: 16px;
  height: auto;
  margin: 0px;
  color: rgba(0, 0, 0, 0.54);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ql-todo-custom {
  padding: 0px !important;
}
.ql-todo-custom p {
  /*font-family: "Roboto", -apple-system, system-ui, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;*/
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 25px !important;
}
.quick-editor {
  padding: 25px 0px 0px 0px !important;
  max-height: 40vh;
}
.quick-editor-notfocused {
  border-top: none rgb(224, 224, 224);
  border-left: none rgb(224, 224, 224);
  border-right: none rgb(224, 224, 224);
  border-bottom: 1px solid rgb(224, 224, 224);
  bottom: 8px;
  padding-top: 4px;
  box-sizing: content-box;
  margin: 0px;

  width: 100%;
}
.quick-editor-focused {
  border-top: none rgb(49, 27, 146);
  border-left: none rgb(49, 27, 146);
  border-right: none rgb(49, 27, 146);
  padding-top: 4px;
  border-bottom: 2px solid rgb(49, 27, 146);
  bottom: 8px;
  box-sizing: content-box;
  margin: 0px;

  width: 100%;

  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}

.custom-icon-button {
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}

.custom-modal-dialog {
  bottom: 10px;
}

.quick-date-picker-row {
  line-height: 2em;
}
.quick-date-picker-cell {
  width: 14.2%;
  text-align: center;
  cursor: pointer;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  &:hover {
    cursor: pointer;
    background-color: lightgray;
    font-weight: bold;
    color: black;
  }
}
.quick-date-picker-cell-month {
  line-height: 1.2em;
  font-size: 0.8em;
}

.custom-nomargin {
  margin: 0px !important;
}

.note-image
{

  position: relative;
  
}
.image-progress-container
{
  position: absolute;
  left: 0px;
  bottom: 0px;
  height: 25px;
  opacity: 0.5;
  text-align: center;
  width: 100%;
  color: #000 !important;
  background-color: #f1f1f1 !important;
}
.image-progress-container-percentage{
  color: #000!important;
  background-color: #9e9e9e!important;
  width: 0%;
    height: 100%;
    top: 0;
    transition: all 300ms;
    position: absolute;
}
.image-progress-status{
  color: black;
    font-size: 20px;
    position: absolute;
    left: 0px;
    bottom: 0px;
    height: 100%;
    text-align: center;
    right: 0;
}

.custom-bottom-bar
{
   position: absolute;
   bottom: 0;
   width: 100%;
   padding:0px;
   z-index: 9999999;
   margin:0px;
   height: 50px;
}

.simple-inbox-section
{
   background: white;
   padding:0px;
   padding-bottom: 8px;
}
.simple-todo-listitem{
 
  padding-top:5px;
  padding-bottom:5px;
  padding-left:25px;
  padding-right:10px;
  height: 35px;
  
}
.simple-todo-listitem-focused{
  margin-left: -4px;
  background-color:#f4f6fb;
}
.simple-todo-listitem-hovering {
    background-color:#f4f6fb;
}
.simple-todo-listitem-selected {
  background-color: #311b9224
}

.simple-todo-subtext-div{
  color: #757575;
 
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.simple-section-heading{

  color: darkgray;
  padding-left: 52px;
  font-weight: 200;
  padding-top: 5px;
  padding-bottom: 5px;

}
.simple-todo-description{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 98%;

  
  /*@media (max-resolution: 1dppx) {*/
  /*letter-spacing: 0.005em $important;*/
  /*}*/
  position: relative;
  


}
.simple-todo-container
{
  padding-left: 38px;
   flex: 1 1 0;
   font-size: 13px;
   line-height: 36px;
   letter-spacing: 0.0jjjj5em;
   -webkit-font-smoothing: antialiased;
   cursor: pointer;
}
.simple-todo-selected{
  
}
.simple-nav{
  padding-left:0px;
   padding-right:10px;
   padding-top:10px;
   padding-bottom:10px;
   flex: 1 1 0;
   font-size: 16px;
   line-height: 20px;
   letter-spacing: 0.0jjjj5em;
   -webkit-font-smoothing: antialiased;
   display: inline-flex;
  

}
.simple-header{
  background-color: white;
}
.simple-nav-action-button-section{
  padding-top: 20px;
  margin-right:18px;
    text-align: right;
  
}

.simple-nav-item-icon{
  margin-top: -3px;
}
.simple-nav-icon-position{
   display: inline;
    position: relative;
    top: 1px;
  
}
.simple-todo-tag-name{
  padding-left: 5px;
   font-weight: bold;
   word-spacing: -5px;
}

.simple-nav-item{
  display: flex;
  padding:10px;
  color:black;
  cursor: pointer;
  opacity: .55;
  font-weight: 400;
  transform: opacity 500ms ease-out 1s;
  &:hover {
    opacity: 1
  
   
  }
}


.simple-nav-item-selected{
  opacity: .95;


}
.simple-todo-hover-menu
{
  width: 100%;
  margin: 0px;
  background:transparent;
  padding: 0px;
  position: absolute;
  right: 0px;
  top:-15px;
}
.simple-todo-focused{
 
    border-left: #311b92;
    border-left-width: 4px;
    border-left-style: solid;
  
 
  
}


.simple-icon-button-icon{
  
  
    width: 24px;
    height: 24px;

    text-align: center;
    line-height: 24px;
    vertical-align: middle;
  
  opacity: 0.3;
  transition: opacity 150ms ease;
  position: relative;
  display: inline;
  
  &:hover {
    cursor: pointer;
    opacity: 1;
    fill:black;
    border-radius: 50%;
    background-color:white;
    box-shadow: 0px 1px 1px 0px rgba(0,0,0,0.34);
   
  }
}
.simple-icon-button-container
{
  display: inline;
  margin-left: 4px;
  margin-right: 4px;
}
.simple-icon-button-containerx
{ 
  border-radius: 50%;
  margin-left: 4px;
  margin-right: 4px;
  display: inline;
  padding:2px;
  background-color:white;
    box-shadow: 0px 1px 1px 0px rgba(0,0,0,0.34);

  &:hover {
    background-color:white;
    box-shadow: 0px 1px 1px 0px rgba(0,0,0,0.34);
   
  }
}
.simple-boby-container
{
  height: calc(100% - 60px);
  overflow: auto;
  background-color: white;
}
.simple-icon-button{
  padding:0px;
  
} 
.simple-main
{
  height: 100%;
  background-color: #f4f6fb;
  padding:0px

}
.simple-body
{
/*  -webkit-box-shadow: 1px 0px 10px 0px rgba(0,0,0,0.34); 
  box-shadow: 1px 0px 10px 0px rgba(0,0,0,0.34);*/
}
.simple-infobar{
 
  color:darkgray;
}

.simple-sidebar{
    position: absolute;
   left:0;
   top:0;
   bottom:0;
   overflow: hidden;
   background: white;
   z-index: 100;
   -webkit-box-shadow: 1px 0px 10px 0px rgba(0,0,0,0.34); 
   box-shadow: 1px 0px 10px 0px rgba(0,0,0,0.34);
  
}
.simple-sidebar-show
{
  visibility: visible;
  transition: visibility 0ms,width 100ms ease-in-out;
   width: 400px;
}
.simple-sidebar-hidden
{
  transition: visibility 200ms,width 100ms ease-in-out;
  visibility: none;
   width: 0px;
   
}



.simple-action-title
{
    padding: 20px 0 5px 0;
    font-size: 16px;
    line-height: 24px;
    font-weight: normal;
    letter-spacing: 0;
    -webkit-font-smoothing: antialiased;
    position: relative;
    top: 0px;
    color: rgba(0, 0, 0, 0.8);
    white-space: nowrap;
}
.simple-action-content
{
  color: rgba(0, 0, 0, 0.55);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 32px 2rem 24px 2rem;
}


.simple-action-subtable-table {
  border-collapse: collapse;
  white-space: nowrap;
  width: 100%;
  margin-top: 2px;
  margin-bottom: 20px; }

.simple-action-subtable-wrapper {
  flex: 2; }

.simple-action-subtable-table td {
  height: 24px;
  line-height: 2em;
  display: table-cell; }

.simple-action-shortcut-name {

 
  color: rgba(0, 0, 0, 0.7);
}
.simple-action-content-cutoff
{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.simple-action-shortcut-row {
   line-height: 2em
}




.superlist-section-heading{

  color: darkgray;
  padding-left: 54px;
  font-weight: 900;
  padding-top: 14px;
  font-size: 12px;
  padding-bottom: 2px;

}
.superlist-todo-listitem{

}
.superlist-todo-listitem-focused{
  
  background-color:#f4f6fb
}
.superlist-todo-listitem-hovering {
  background-color:#f8faff;
 
}
.superlist-todo-listitem-selected {
  background-color: #311b9224
}

.superlist-todo-listitem-selected-dark{
  background-color: rgba(49,27,146,.34)
}
.superlist-todo-listitem-focused-dark{
  
  background-color:rgba(49,27,146,.24)
}
.superlist-todo-listitem-hovering-dark {
    background-color:rgba(49,27,146,.24)
}
.superlist-todo-mobilelistitme
{
  max-height: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  background: white;
  
}
.superlist-todo-mobilelistitem-boarder
{
 
  border-bottom: 1px lightgray solid;
}
.superlist-expanded-mobile-group-end
{
  color: #888888;
  background: #eceaea;
  padding-left: 19px;
  font-weight: 900;
  
  font-size: 12px;
  height: 10px;
  border-top: 2px solid darkgray;
}
.superlist-mobile-group-heading {
  color: #888888;
  background: #eceaea;
  padding-left: 19px;
  font-weight: 900;
  padding-top: 10px;
  font-size: 12px;
  height: 40px;
  border-top: 2px solid darkgray;
}
.superlist-mobile-paddingrow{
  color: #888888;
  background: #eceaea;
}
.superlist-mobile-section-heading {
  color: #888888;
  background: #eceaea;
  padding-left: 19px;
  font-weight: 900;
  padding-top: 14px;
  font-size: 12px;
  height: 40px;
}
.mobile-todo-description{
  overflow: hidden;
  text-overflow: ellipsis;
  
  
  /*@media (max-resolution: 1dppx) {*/
  /*letter-spacing: 0.005em $important;*/
  /*}*/
  position: relative;
  


}

.superlist-expanded-group-heading{

  color: darkgray;
  padding-left: 55px;
  font-weight: 900;
  padding-top: 5px;
  padding-bottom: 0px;
  font-size: 13px;

 

}
.superlist-expanded-group-line
{
  padding-left: 0px;
  margin-left: 52px;
  /* margin-right: 80px; */
  margin-top: 0px;
  margin-bottom: 0px;
}
.superlist-expanded-group-end
{
  padding: 5px;
}

.superlist-todo-tagname-container
{
   display: flex;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.superlist-todo-tagname-icon-container
{
  display: inline;
 
  
}
.superlist-todo-tag-name{
  
  padding-left: 5px;
  font-weight: bold;
  word-spacing: -5px;
  white-space: nowrap;
   display: table-cell;
   max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.superlist-logo
{
  background-image: url('../todozero-logo.svg');
  background-repeat: no-repeat;
  background-position: left;
  
  height: 18px;
  background-size: 100% 18px;
}
.superlist-logo-image
{
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  src:url('../todozero-logo.svg');
  height: 18px;
    position: absolute;
    left: 0;

}
.superlist-container-web
{
  flex: 1 1 auto;
  height:calc(100vh - 64px)
}

.superlist-container-mobile
{
  flex: 1 1 auto;
  height:calc(100vh);
  background: #eceaea;
 /* height:calc(100vh - 64px)*/
}

.logo-settings
{
  position: absolute;
    bottom: 0px;
   
   
    left: 0;
    right: 0;

}
.darkmode-toggle
{
  z-index: 9999999;
  display: none;
}
.darkmode-layer {
  z-index: 9999999;
}


.mention-popup
{
  z-index: 9999999;
}
.suggestion-item-active{
  color:red
}
.prose-tag
{
  
}




////@extend
.atom-category {
  color: #fff;
  margin-right: 6px;
  border-radius: 2px;
  padding: 1.2px 3px;
}
.atom-shortcut {
  float: right;
  margin-right: 2px;
  display: inline-block;
  margin-left: 0.45454545em;
  padding: 0 0.375em;
  line-height: 2;
  margin-top: -0.375em;
  font-family: inherit;
  font-size: 1em;
  letter-spacing: 0.1em;
  border-radius: 3px;
  color: #fff;
  background-color: #4d78cc;
}

.atom-category.Command {
  background: rgb(67, 130, 207);
}
.atom-category.Navigate {
  background: rgb(165, 22, 134);
}
.atom-category.Network {
  background: rgb(46, 41, 194);
}
.atom-category.System {
  background: rgb(49, 177, 79);
}
.atom-category.Drawer {
  background: rgb(206, 64, 206);
}

///

.note-image{
  cursor: pointer
}