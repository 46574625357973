// Desktop only, for boxed layout
.app-main {
  max-width: 100%;
  margin: auto;
  transition: max-width .35s ease;

  .app-sidebar {
    left: auto;
  }

  .app-header {
    > .app-header-inner {
      max-width: 100%;
      margin: auto;
      transition: max-width .35s ease;
    }
  }
}

@media only screen and (min-width: $l_screen_md_min) {
  .layout-boxed {
    &.app-main {
      max-width: $l_layout_boxed_max_width;
      box-shadow: 0 0 1px rgba(0,0,0,.2);

      .app-header {
        // in boxed mode, .app-header position is the same, only style of .app-header-inner is changed
        > .app-header-inner {
          max-width: $l_layout_boxed_max_width;
          box-shadow: 0 1px 1px rgba(0,0,0,.2); // Note: it overrides default $header_box_shadow
        }
      }

      .app-overlay {
        > .app-overlay-inner {
          max-width: $l_layout_boxed_max_width;
          margin: auto;
        }
      }
    }
  }
}