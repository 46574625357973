// overrides mdl
address {
  line-height: $line-height-base;
}

h1, h2, h3, h4, h5, h6 {
  line-height: $headings-line-height;
}
.note-view-mod{
  margin-left:'5px';
  margin-right:'5px';
  &.with-maxwidth-sm {
    margin-left:'0px';
    margin-right:'0px';
  }
}

.container-fluid-mod {
  padding-left: 30px;
  padding-right: 30px;
  
  @media (max-width: map-get($grid-breakpoints, md)) {
    padding-left: 0px;
    padding-right: 0px;
  }
  &.padding-lg-h {
    padding-left: $grid-gutter-width-base;
    padding-right: $grid-gutter-width-base;
  }
  &.padding-md-h {
    padding-left: 0px;
    padding-right:0px;
  }
  &.padding-sm-h {
    padding-left: 0px;
    padding-right: 0px;
  }
  &.with-maxwidth {
    max-width: $container_fluid_maxwidth;
  }
  &.with-maxwidth-lg {
    max-width: map-get($container-max-widths, lg);
  }
  &.with-maxwidth-md {
    max-width: map-get($container-max-widths, lg);
  }
  &.with-maxwidth-sm {
    max-width: map-get($container-max-widths, md);
  }
  &.no-breadcrumbs {
    padding-top: 2em;
    padding-bottom: 1em;
  }
}

.mt-0 {
  margin-top: 0 !important;
}

.ml-1 {
  margin-left: ($spacer-x * .25) !important;
}

.px-2 {
  padding-left: ($spacer-x * .5) !important;
  padding-right: ($spacer-x * .5) !important;
}

.p-3 {
  padding: $spacer-y $spacer-x !important;
}


.fabnote {
  margin: 0px;
  top: auto;
  right: 0px;
  bottom: 70px;

  left: auto;
  position: fixed;
};

.select-note-open {
  

  
 

}
.select-note-close {

 
  @media (max-width: map-get($grid-breakpoints, lg)) {
    display:none;
  }

}
.note-box
{
  padding-bottom:15px;
  @media (max-width: map-get($grid-breakpoints, md)) {
    padding-bottom:0px;
  }
}
.edit-todo-model
{
  @media (max-width: map-get($grid-breakpoints, md)) {
    
  }
}
/*
.select-note-open {
  

    @media (max-width: map-get($grid-breakpoints, md)) {
      transition: 0.2s;
      width: 100%;
    }
   
  
}
.select-note-close {
  


  @media (max-width: map-get($grid-breakpoints, md)) {
    transition: 0.2s;
    width: 0%;
  }

}*/