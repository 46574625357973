// toggler
// ------
.sidebar-header {
  a.collapsednav-toggler {
    display: inline-block;
    position: absolute;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    right: 18px;
    top: 20px; // (60 - 20) /2
    color: rgba(255,255,255,.55);
    // padding: 0 5px;
    .material-icons {
      font-size: 12px;
    }
  }
}
// mobile
@media only screen and (max-width: $l_screen_sm_max ) {
  .app-sidebar .sidebar-header a.collapsednav-toggler {
    display: none;
  }
}


// When hover over collapsed nav, it becomes normal nav again, the css is used twice, both normal state & hover state
// So put those shared style in mixins, call those mixins once when it's normal nav, and another when hover over collapsed nav

@mixin normalSidebarHeader() {
  text-align: left;
  .logo-icon {
    margin-right: 11px;
  }
  .logo-img {
    margin-right: 16px;
  }
  .brand {
    display: inline;
  }
  .collapsednav-toggler {
    display: inline-block;
  }
}
@mixin normalSidebarContent() {
  .nav-header {
    display: block;
  }
  .nav-text {
    display: inline;
  }
  .icon-has-ul {
    display: inherit;
  }
  .badge {
    display: inherit;
    top: 14px;
    right: 35px;
  }

  .nav > li {
    // 1st level ul
    > a {
      padding: 10px 16px !important;
      text-align: left !important;
      .nav-icon {
        transition: padding .35s $l_transition_ease_out,
        margin  .35s $l_transition_ease_out;
        margin-right: 18px;
      }
      // > .badge {
      //   top: 12px;
      // }
    }

    // >= 2nd level ul
    ul {
      li {
        > a {
          text-align: left !important;
          > span {
            display: inline;
          }

          > .material-icons {
            transition: margin .35s $l_transition_ease_out;
            margin-right: 18px;
          }
          > div {
            > span { // for materail-ui FlatButton
              display: inline;
            }
            > .material-icons {
              transition: margin .35s $l_transition_ease_out;
              margin-right: 18px;
            }
          }
        }
      }
    }
  }
}
@mixin normalSidebarFooter() {
  .nav-text {
    display: inline;
  }
  .nav > li {
    > a {
      padding: 10px 16px !important;
      text-align: left !important;
      .nav-icon {
        margin-right: 15px;
      }
    }
  }
}

// normal
.app-sidebar {
  .sidebar-header {
    @include normalSidebarHeader();
  }
  .sidebar-content {
    @include normalSidebarContent();
  }
  .sidebar-footer {
    @include normalSidebarFooter();
  }
}


// desktop
// nav-collapsed, .nav-behind is desktop only
@media only screen and (min-width: $l_screen_md_min) {

  // default style is normal nav
  .app-sidebar {
    transition: width $l_sidebar_collpase_duration $l_transition_ease_out;
    white-space: nowrap;
  }

  // when collapsed
  .nav-collapsed .app-sidebar{
    width: $l_sidebar_width_collapsed;

    .sidebar-header {
      text-align: center;
      .logo-icon,
      .logo-img {
        margin-right: 0;
      }
      .brand {
        display: none;
      }
      .collapsednav-toggler {
        display: none;
      }
    }

    .sidebar-content {
      .nav-header,
      .nav-text,
      .icon-has-ul,
      // .badge,
      .nav ul a > span { // sub ul text
        display: none;
      }
      .nav ul a > div { // for materail-ui FlatButton
        > span { 
          display: none;
        }
      }

      .badge {
        top: 3px;
        right: 5px;
      }

      .nav > li {
        > a {
          padding: 12px 16px !important;
          text-align: center !important;
        }

        .nav-icon {
          margin-right: 0;
        }

        // because of jQuery 
        ul {
          li {
            > a {
              text-align: center !important;
              > .material-icons {
                margin-right: 0;
              }

              > div > .material-icons { // for materail-ui FlatButton
                margin-right: 0;
              }
            }
          }
        }
      }
    }

    .sidebar-footer {
      .nav-text {
        display: none;
      }
      .nav > li {
        > a {
          text-align: center !important;
        }

        .nav-icon {
          margin-right: 0;
        }
      }
    }
  }

  // reset to normal style
  .nav-collapsed .app-sidebar:hover{
    width: $l_sidebar_width;

    // solve ".badge, .icon-has-ul floating on text when hover over collapsed nav" problem
    // however this effect doesn't apply to remove/add `.nav-collapsed` class using ng-class
    > * {
      width: $l_sidebar_width;
    }

    .sidebar-header {
      @include normalSidebarHeader();
    }
    .sidebar-content {
      @include normalSidebarContent();
    }
    .sidebar-footer {
      @include normalSidebarFooter();
    }
  }
}


