.box {
  position: relative;
  margin-bottom: 1.5rem;
  border: 0;
  border-radius: $border-radius;
  box-shadow: 0 0 2px rgba(0,0,0,.15);

  &.box-transparent {
    box-shadow: none;
  }

  .box-dark {
    background-color: rgba(0,0,0,.03);
  }
}

// put color in .box-default, so .bg-xxx can overrides box color easily
.box-default {
  background-color: $card-bg;
}

.box-header,
.box-heading {
  padding: $card-spacer-y $card-spacer-x;
  border-bottom: 1px solid transparent;
  border-radius: ($border-radius - 1) ($border-radius - 1) 0 0;
  font-weight: $headings-font-weight;
  // text-transform: uppercase;
}

.box-toolbar {
  position: absolute;
  top: $card-spacer-x;
  right: $card-spacer-x;

  a {
    color: $text-muted;

    .material-icons {
      font-size: $font-size-base;
    }
  }
}

.box-divider {
  height: 1px;
  background: rgba(0,0,0,0.08);
  margin: 0 $card-spacer-x;
}

.box-body {
  @include clearfix;
  padding: $card-spacer-x;

  &.padding-lg {
    padding: 30px 15px;
  }
  &.padding-xl {
    padding: 60px 15px;
  }
  &.padding-lg-h {
    padding: 15px 30px;
  }
  &.padding-lg-v {
    padding: 30px 15px;
  }
}


// Theme
// --------------------------------------------------
.theme-gray {
  .box-default {
    background-color: $theme_gray_box_bg;
  }
}
.theme-dark {
  .box-default {
    background-color: $theme_dark_box_bg;
  }
}


